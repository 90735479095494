@import url('https://fonts.googleapis.com/css?family=Crimson+Text:400,400i,600,700&display=swap');

body {
  margin: 0;
  font-family: "Minion Pro", "Crimson Text", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;

  /* background-color: hsl(212, 50%, 60%); */
}

@font-face {
  font-family: 'Minion Pro';
  src: url('./assets/MinionPro-It.woff2') format('woff2'),
      url('./assets/MinionPro-It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Minion Pro';
  src: url('./assets/MinionPro-Bold.woff2') format('woff2'),
      url('./assets/MinionPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Minion Pro';
  src: url('./assets/MinionPro-Medium.woff2') format('woff2'),
      url('./assets/MinionPro-Medium.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Minion Pro';
  src: url('./assets/MinionPro-Regular.woff2') format('woff2'),
      url('./assets/MinionPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

