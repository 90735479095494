@import url(https://fonts.googleapis.com/css?family=Crimson+Text:400,400i,600,700&display=swap);
body {
  margin: 0;
  font-family: "Minion Pro", "Crimson Text", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 16px;

  /* background-color: hsl(212, 50%, 60%); */
}

@font-face {
  font-family: 'Minion Pro';
  src: url(/static/media/MinionPro-It.96704564.woff2) format('woff2'),
      url(/static/media/MinionPro-It.f4cf61be.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Minion Pro';
  src: url(/static/media/MinionPro-Bold.e59e7144.woff2) format('woff2'),
      url(/static/media/MinionPro-Bold.db71ca0b.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Minion Pro';
  src: url(/static/media/MinionPro-Medium.9fdbc74d.woff2) format('woff2'),
      url(/static/media/MinionPro-Medium.a73f7036.woff) format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Minion Pro';
  src: url(/static/media/MinionPro-Regular.8de07422.woff2) format('woff2'),
      url(/static/media/MinionPro-Regular.b73c6905.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}


.switch-wrapper {
  position: relative;
}
 
.switch-wrapper > div {
  position: absolute;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.app {
  /* background-color: #E6DAA9; */
  background-color: hsl(51, 20%, 93%);
  color: #130303;
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: 140%;
  padding: 10px;
}

a,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  max-width: 1200px;

  font-size: 25px;
}

header>* {
  margin: 5px 10px;
}

nav ul {
  margin: 0;
  padding: 0;
}

nav ul li {
  list-style: none;
}

nav ul li a {
  color: #281919;
  font-style: italic;
  padding: 5px 10px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-transition: -webkit-transform 0.05s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: -webkit-transform 0.05s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.05s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.05s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.05s cubic-bezier(0.645, 0.045, 0.355, 1);
}

nav ul li:hover {
  background-color: #E3E4DB;
  cursor: pointer;
}

nav ul li:active,
.cta:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
  cursor: pointer;
}

.active {
  background-color: #E3E4DB;
}

h1 {
  font-size: 250%;
  font-weight: 400;
  margin: 10px 0;
}

h2 {
  font-size: 125%;
  font-weight: 800;
  position: relative;
}

.logo {
  background-color: #130303;
  color: #E3E4DB;
  padding: 5px 10px;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;

  flex: 1 1;
}


main.home {
  margin-top: -60px;
  justify-content: space-around;
}

.main {
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  align-items: center;
  position: relative;
  max-width: 100%;
}

.contact-cta {
  position: relative;
  top: 25px;
  font-size: 125%;
  color: #E3E4DB;
  background-color: #003459;
  padding: 5px 10px;
  font-weight: 600;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.contact-cta:hover {
  cursor: pointer;
}

.headlines {
  text-align: center;
}

.sc {
  font-feature-settings: "c2sc";
  font-variant: small-caps;
  letter-spacing: 0.4px;
}

.videos {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  max-width: 85vw;
  position: relative;
}

.videos .video {
  margin-right: 20px;
}

.videos .video iframe {
  height: 80vh;
  max-height: 300px;
  width: 80vw;
  max-width: 500px;
}

.raised {
  box-shadow: 5px 5px 10px 2px rgba(19, 3, 3, 0.25), 0px 2px 5px 0px rgba(19, 3, 3, 0.25);
  /* box-shadow: 5px 5px 10px 2px rgba(19, 3, 3, 0.25); */
  -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.raised:hover {
  box-shadow: 5px 5px 20px 3px rgba(19, 3, 3, 0.25);
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  -webkit-filter: brightness(1.05);
          filter: brightness(1.05);
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

form>* {
  display: flex;
  flex-direction: column;
}

input,
textarea,
button[type=submit] {
  box-shadow: 5px 5px 10px 1px rgba(19, 3, 3, 0.25);
  font-family: inherit;
  border-radius: 0;
  background: rgba(256, 256, 256, 0.8);
  border: 2px solid #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 18px;
  line-height: 20px;
  padding: 8px;
  border: 0;
  margin: 8px;
  -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  outline: none;
}

input:hover,
textarea:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

input:focus,
textarea:focus {
  -webkit-transform: scale(1);
          transform: scale(1);
  box-shadow: 2px 2px 5px 1px rgba(19, 3, 3, 0.25);
}

button[type=submit] {
  background-color: #130303;
  color: #E3E4DB;
  font-weight: bold;
  cursor: pointer;
}

.emph {
  background-image: linear-gradient(45deg, hsl(205, 60%, 75%) 0%, #E6DAA9 100%);
  background-size: 170% 50%;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  -webkit-transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.emph:hover {
  background-size: 170% 100%;
}

@media (max-width: 1000px) {
  .app {
    font-size: 120%;
    top: 12px;
    right: 12px;
    bottom: 12px;
    left: 12px;
  }

  header {
    font-size: 130%;
  }

  h1 {
    font-size: 220%;
  }

  h2 {
    font-size: 120%;
  }
}

@media (max-width: 350px) {
  .app {
    font-size: 100%;
  }

  header {
    font-size: 130%;
  }

  h1 {
    font-size: 200%;
  }

  h2 {
    font-size: 100%;
  }
}
